import React, { Suspense, useEffect } from "react";
//import {Auth}  from './agent';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import i18n from "./i18n";

import DashUserPlace from "./components/DashBoard.js/DashPlace/DashUserPlace";
import MangeMyPlace from "./components/DashBoard.js/DashPlace/MangeMyPlace.js";
import MangeUserMyPlace from "./components/DashBoard.js/DashPlace/MangeUserMyPlace.js";
import MangeFloorMyPlace from "./components/DashBoard.js/DashPlace/MangeFloorMyPlace.js";
import MangeResMyPlace from "./components/DashBoard.js/DashPlace/MangeResMyPlace.js";
import MangeRoomMyplace from "./components/DashBoard.js/DashPlace/MangeRoomMyplace.js";
import MangeOfferMyPlace from "./components/DashBoard.js/DashPlace/MangeOfferMyPlace.js";
import LoginPlace from "./components/DashBoard.js/DashPlace/LoginPlace.js";
import Updatausermyplace from "./components/DashBoard.js/DashPlace/Updatausermyplace.js";
import UserAddToMyPlace from "./components/DashBoard.js/DashPlace/UserAddToMyPlace.js";
import MangeAllRoom from "./components/DashBoard.js/DashPlace/MangeAllRoom.js";
import AddNewRoommyplace from "./components/DashBoard.js/DashPlace/AddNewRoommyplace.js";
import UpdateRoommyplace from "./components/DashBoard.js/DashPlace/UpdateRoommyplace.js";
import ImageRoomPlace from "./components/DashBoard.js/DashPlace/ImageRoomPlace.js";
import MangeServiceMyPlace from "./components/DashBoard.js/DashPlace/MangeServiceMyPlace.js";
import MangeImagesMyPlace from "./components/DashBoard.js/DashPlace/MangeImagesMyPlace.js";
import Updateadsa from "./components/DashBoard.js/DashPlace/Updateadsa.js";
import MangeFloorMyPlaceUser from "./components/DashBoard.js/DashPlace/MangeFloorMyPlaceUser.js";
import MangeNotifictionMyPlace from "./components/DashBoard.js/DashPlace/MangeNotifictionMyPlace.js";
import ProtectedPlaceRoute from "./components/ProtectedPlaceRoute.js";
import ManageZaincash from "./components/DashBoard.js/DashPlace/ManageZaincash";
import AddPost from "./components/DashBoard.js/DashPlace/AddPost";
import Managpostuser from "./components/DashBoard.js/DashPlace/Managpostuser";
import Updatepost from "./components/DashBoard.js/DashPlace/Updatepost";
import Resetpsw from "./components/DashBoard.js/DashPlace/Resetpsw";
import Privce from "./components/DashBoard.js/DashPlace/Privce";
const App = ({ dir, lang }) => {
  //Auth.login(0,0).then(res=>localStorage.setItem("token",res.data.token));
  ///Auth.current().then(res=>console.log(res.data[0]));

  useEffect(() => {
    if (i18n.language === "ar") {
      localStorage.setItem("langweb", "rtl");
    } else {
      localStorage.setItem("langweb", "ltr");
    }
  });

  return (
    <div dir={dir} lang={lang}>
      <Suspense fallback={<div>Loading</div>}>
        <Router>
          {/*    <Header />*/}
          <Switch>
         {/*
             <Route exact path="/" component={LoginPlace} />
             <Route path="/LoginPlace" component={LoginPlace} />{" "}
            <ProtectedPlaceRoute path="/DashUserPlace" Component={DashUserPlace} />{" "}
            <ProtectedPlaceRoute path="/MangeMyPlace" Component={MangeMyPlace} />{" "}
            <ProtectedPlaceRoute path="/MangeUserMyPlace" Component={MangeUserMyPlace} />{" "}
             <ProtectedPlaceRoute path="/MangeResMyPlace" Component={MangeResMyPlace} />{" "}
            <ProtectedPlaceRoute path="/MangeRoomMyplace" Component={MangeRoomMyplace} />{" "}
            <ProtectedPlaceRoute path="/MangeFloorMyPlace" Component={MangeFloorMyPlace} />{" "}
             <ProtectedPlaceRoute path="/MangeOfferMyPlace" Component={MangeOfferMyPlace} />{" "}

            <ProtectedPlaceRoute path="/MangeServiceMyPlace" Component={MangeServiceMyPlace} />{" "}
            <ProtectedPlaceRoute path="/MangeImagesMyPlace" Component={MangeImagesMyPlace} />{" "}
               <ProtectedPlaceRoute path="/MangeNotifictionMyPlace" Component={MangeNotifictionMyPlace} />{" "}

            
            <ProtectedPlaceRoute path="/Updatausermyplace" Component={Updatausermyplace} />{" "}
            <ProtectedPlaceRoute path="/UserAddToMyPlace" Component={UserAddToMyPlace} />{" "}
            <ProtectedPlaceRoute path="/MangeAllRoom" Component={MangeAllRoom} />{" "}
            <ProtectedPlaceRoute path="/AddNewRoommyplace" Component={AddNewRoommyplace} />{" "}
            <ProtectedPlaceRoute path="/UpdateRoommyplace" Component={UpdateRoommyplace} />{" "}
            <ProtectedPlaceRoute path="/ImageRoomPlace" Component={ImageRoomPlace} />{" "}
            <ProtectedPlaceRoute path="/Updateads" Component={Updateadsa} />{" "}
            <ProtectedPlaceRoute path="/MangeFloorMyPlaceUser" Component={MangeFloorMyPlaceUser} />
            <ProtectedPlaceRoute path="/ManageZaincash" Component={ManageZaincash} />
            <ProtectedPlaceRoute path="/AddPost" Component={AddPost} />
            <ProtectedPlaceRoute path="/Managpostuser" Component={Managpostuser} />
            <ProtectedPlaceRoute path="/Updatepost" Component={Updatepost} />
            */}
            <Route path="/users/password-reset/:userid/:token" component={Resetpsw}  />
            <Route path="/privacy" component={Privce}  />

            
            
            {" "}

            

          </Switch>
          {/* <Contact/>*/}
          {/*  <Footer/>*/}
        </Router>
      </Suspense>
    </div>
  );
};

export default App;
